import React, { Component } from 'react';
import {Switch, Route} from 'react-router-dom';
import PageTransition from "react-router-page-transition";

import Home from './components/Home';
import Portfolio from './components/Portfolio';
import MesValeurs from './components/MesValeurs';

class App extends Component {
  render() {
    return (
      <Route
        render={({ location }) => (
          <PageTransition timeout={500}>
            <Switch location={location}>
              <Route exact path='/' component={Home}/>
              <Route path='/portfolio' component={Portfolio}/>
              <Route path='/mes-valeurs' component={MesValeurs}/>
              <Route component={Home} />
            </Switch>
          </PageTransition>
        )}
      />
    );
  }
}

export default App;
