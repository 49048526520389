import React from 'react';
import {Link} from 'react-router-dom';

import '../../node_modules/magnific-popup/dist/magnific-popup.css';
import $ from 'jquery';
import 'magnific-popup';

import portfolio_logo from '../media/portfolio.svg';
import thumbnail_01 from '../media/thumbnails/thumbnail_01.jpg';
import thumbnail_02 from '../media/thumbnails/thumbnail_02.jpg';
import thumbnail_03 from '../media/thumbnails/thumbnail_03.jpg';
import thumbnail_04 from '../media/thumbnails/thumbnail_04.jpg';
import thumbnail_05 from '../media/thumbnails/thumbnail_05.jpg';

class MagnificPopup extends React.Component {
  render () {
    return (
      <div className="col-lg-4 col-md-6 col-sm-12 py-4">
        <a className="popup-vimeo" href={this.props.url}>
          <img className="rounded img-fluid shadow-material" src={this.props.thumbnail} alt="thumbnail" />
        </a>
      </div>
    )
  }
}

class Portfolio extends React.Component {
  componentDidMount() {
    $('.popup-vimeo').magnificPopup({
      disableOn: 768,
      type: 'iframe',
      mainClass: 'mfp-fade',
      removalDelay: 160,
      preloader: false,
      fixedContentPos: false
    });
  }
  render() {
    return (
      <div className="transition-item portfolio-page d-flex flex-column h-100">
        <div className="container py-5 text-center">
          <div className="row justify-content-center pb-3">
            <div className="col-lg-3 col-md-5 col-sm-6 col-9">
              <img src={portfolio_logo} alt="portfolio_logo" />
            </div>
          </div>
          <div className="row justify-content-center">
            <MagnificPopup url="https://vimeo.com/324733891" thumbnail={thumbnail_01} />
            <MagnificPopup url="https://vimeo.com/324729691" thumbnail={thumbnail_02} />
            <MagnificPopup url="https://vimeo.com/324741014" thumbnail={thumbnail_03} />
            <MagnificPopup url="https://vimeo.com/324745577" thumbnail={thumbnail_04} />
            <MagnificPopup url="https://vimeo.com/324747466" thumbnail={thumbnail_05} />
          </div>
          <div className="row justify-content-center pt-3">
            <div className="col-12">
              <h5>N'hésitez pas à me contacter&nbsp;!</h5>
              <h3><i className="fas fa-envelope"></i> <span className="font-weight-bold">&#099;&#111;&#110;&#116;&#097;&#099;&#116;&#064;&#114;&#111;&#098;&#105;&#110;&#099;&#097;&#114;&#108;&#111;&#046;&#102;&#114;</span></h3>
            </div>
          </div>
        </div>

        <div className="mt-auto">
          <div className="col-auto text-right p-0">
            <Link to='/'>
              <button type="button" className="btn btn-rounded shadow-material">
                <i className="fas fa-home"></i>
              </button>
            </Link>
          </div>
          <footer className="footer py-3">
            <div className="container-fluid">
              <div className="row">
                <div className="col-md col-sm-12 text-md-left text-center">
                  <Link to='/mes-valeurs'><i className="fas fa-handshake"></i> Mes Valeurs</Link>
                </div>
                <div className="col-md col-sm-12 text-center">
                  <i class="far fa-copyright"></i> 2020 robincarlo.fr
                </div>
                <div className="col-md col-sm-12 text-md-right text-center">
                  <a href="https://www.instagram.com/robincarlo_pro/" className="social"><i className="fab fa-instagram-square"></i></a>&nbsp;
                  <a href="https://vimeo.com/carlorobin" className="social"><i className="fab fa-vimeo-square"></i></a>&nbsp;
                  <a href="https://dribbble.com/robincarlo" className="social"><i className="fab fa-dribbble-square"></i></a>&nbsp;
                  <a href="https://www.behance.net/robincarlo" className="social"><i className="fab fa-behance-square"></i></a>&nbsp;
                  <a href="https://www.linkedin.com/in/robincarlo" className="social"><i className="fab fa-linkedin"></i></a>
                </div>
              </div>
            </div>
          </footer>
        </div>
      </div>
    );
  }
}

export default Portfolio;
