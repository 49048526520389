import React from 'react';
import {Link} from 'react-router-dom';

class MesValeurs extends React.Component {

  render() {
    return (
      <div>
        <div className="container py-3">
          <div className="row">
            <div className="col-11">
              <h1>Mes Valeurs</h1>
            </div>
            <div className="col-1">
              <Link to='/portfolio'>
                <button type="button" className="btn btn-rounded shadow-material position-fixed">
                  <i className="fas fa-times"></i>
                </button>
              </Link>
            </div>
          </div>
          <div className="row">
            <div className="col-12">
              <hr />
              <h3>Pourquoi est-il important de les préciser ?</h3>
              Pour qu’un projet aboutisse et soit surtout à la hauteur de vos attentes, il est primordial d’établir une relation de confiance, et ce dès le début de notre collaboration.
              Pour cela, je suis convaincu que partager les mêmes valeurs est essentiel.
              <hr />
              <h4><span class="badge badge-dark">1</span> <u>L’Engagement</u></h4>
              L’engagement est la première étape.<br />
              Tout projet fera objet d’un devis signé ainsi que d’un acompte de 30%. Vous devrez également accepter et respecter mes <a href='../cgv.pdf' target="_blank">CGV</a>.<br />
              En échange, je m’engagerai à vous fournir exactement ce que je vous ai promis.
              <div className="py-3"/>
              <h4><span class="badge badge-dark">2</span> <u>L’Honnêteté</u></h4>
              Soyons honnête l’un envers l’autre.<br />
              Ça sera le meilleur moyen d’arriver au bout de votre projet mais surtout de l’accomplir avec succès.
              <div className="py-3"/>
              <h4><span class="badge badge-dark">3</span> <u>La Coopération</u></h4>
              Notre coopération est indispensable.<br />
              En s’aidant mutuellement, nous ne pourrons qu’être plus efficaces.
              <div className="py-3"/>
              <h4><span class="badge badge-dark">4</span> <u>Le Respect</u></h4>
              Le respect est primordial, et dans les deux sens.<br />
              Je vous respecterai et je m’attendrai à recevoir le même traitement de votre part.
              <hr />
              Si toutes ces qualités vous paraissent être du bon sens, cela signifie alors qu’on est fait pour travailler ensemble !<br />
              Dans le cas contraire, je vous invite à me faire part de vos retours afin que l’on se mette d’accord sur notre façon de travailler.
              <hr />
              <h5><u>Remerciements</u></h5>
              <a href="https://www.gandi.net/fr/no-bullshit">Gandi.net</a> : Pour leur promesse No Bullshit™ que je partage entièrement et dont je me suis grandement inspiré pour rédiger cette page.<br />
              <a href="https://kitdesurvie.metiers-graphiques.fr/">kitdesurvie.metiers-graphiques.fr</a> : Pour leur éducation juste et nécessaire du graphiste freelance.
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default MesValeurs;
