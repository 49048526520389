import React, { Component } from 'react';
import {Link} from 'react-router-dom';

import logo from '../media/logo.svg';
import bg from '../media/bg.mp4';

class Home extends Component {
  render() {
    return (
      <div className="transition-item home-page h-100">
        <div className="videoBgWrapper">
          <video loop muted autoPlay className="videoBg">
              <source src={bg} type="video/mp4"/>
          </video>
        </div>
        <div className="container h-100">
          <div className="row h-100 justify-content-center align-items-center">
            <div className="col-lg-6 col-md-9 col-sm-12">
              <div className="container">
                <div className="row pb-2">
                  <div className="col-12">
                    <img src={logo} alt="logo" />
                  </div>
                </div>
                <div className="row justify-content-center">
                  <div className="col-sm-5 py-1">
                    <Link to='/portfolio'>
                      <button type="button" className="btn btn-block btn-material shadow-material">Portfolio</button>
                    </Link>
                  </div>
                  <div className="col-sm-5 py-1">
                    <a href='../cv.pdf' target="_blank">
                      <button type="button" className="btn btn-block btn-material shadow-material">CV</button>
                    </a>
                  </div>
                </div>
                <div className ="row justify-content-center text-white pt-2 infos">
                  <div className="col-auto">
                    <h5><i className="fas fa-map-marker-alt"></i> Île de France</h5>
                  </div>
                  <div className="col-auto">
                    <h5><i className="fas fa-envelope"></i> &#099;&#111;&#110;&#116;&#097;&#099;&#116;&#064;&#114;&#111;&#098;&#105;&#110;&#099;&#097;&#114;&#108;&#111;&#046;&#102;&#114;</h5>
                  </div>
                </div>
                <div className="row justify-content-center">
                  <a href="https://www.instagram.com/robincarlo_pro/" className="social px-1"><i className="fab fa-instagram-square fa-2x"></i></a>
                  <a href="https://vimeo.com/carlorobin" className="social px-1"><i className="fab fa-vimeo-square fa-2x"></i></a>
                  <a href="https://dribbble.com/robincarlo" className="social px-1"><i className="fab fa-dribbble-square fa-2x"></i></a>
                  <a href="https://www.behance.net/robincarlo" className="social px-1"><i className="fab fa-behance-square fa-2x"></i></a>
                  <a href="https://www.linkedin.com/in/robincarlo" className="social px-1"><i className="fab fa-linkedin fa-2x"></i></a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Home;
